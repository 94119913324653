:root {
  @apply .base;
}

.base {
  --color-bg-primary: #f6f8f8;
  --color-bg-secondary: #ffffff;
  --color-text-primary: #263635;
  --color-welcome-msg: #a9cee0;
  --color-button-close: #1a7167;
  --color-button-hover: #114b47;
  --color-text-accent: #2b6cb0;
}

.student {
  --color-bg-primary: #0e1211;
  --color-bg-secondary: #1f2524;
  --color-text-primary: #fff;
  --color-text-secondary: #0e1211;
  --color-welcome-msg: #f4a38c;
  --color-text-accent: #81e6d9;
  --color-button-close: #fff;
  --color-button-hover: #95d0c8;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Conduit';
  src: url('./assets/fonts/a5a0edc0-b571-4275-9c51-3dfeba35b75a.eot');
  src: url('./assets/fonts/a5a0edc0-b571-4275-9c51-3dfeba35b75a.eot') format('eot'),
    url('./assets/fonts/a81af59b-a5d9-4e96-a7cc-919403c0d86c.woff2') format('woff2'),
    url('./assets/fonts/e93ee223-5d52-4bdf-a113-c6c4c8936824.woff') format('woff'),
    url('./assets/fonts/ccdadc2e-26c9-48a5-9c52-9c3cc58e9930.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Conduit';
  src: url('./assets/fonts/45fa0132-0858-4e18-9594-5a070788a059.eot');
  src: url('./assets/fonts/45fa0132-0858-4e18-9594-5a070788a059.eot') format('eot'),
    url('./assets/fonts/0f250d3b-13fd-469c-a4ec-9f9984165dea.woff2') format('woff2'),
    url('./assets/fonts/e924c59f-bc21-471f-ad1b-cefd0c1a6315.woff') format('woff'),
    url('./assets/fonts/13b88c12-e5f0-46c4-932a-57fe881b7c46.ttf') format('truetype');
  font-weight: 200;
}
